// Generated by Framer (a387266)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["nPgUauaQN", "ejpRcwCiH"];

const serializationHash = "framer-SsOwH"

const variantClassNames = {ejpRcwCiH: "framer-v-9a84u5", nPgUauaQN: "framer-v-l9fx6z"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {White: "ejpRcwCiH", Yellow: "nPgUauaQN"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "nPgUauaQN"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "nPgUauaQN", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-l9fx6z", className, classNames)} data-framer-name={"Yellow"} layoutDependency={layoutDependency} layoutId={"nPgUauaQN"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(241, 255, 0)", borderBottomLeftRadius: 200, borderBottomRightRadius: 200, borderTopLeftRadius: 200, borderTopRightRadius: 200, ...style}} variants={{ejpRcwCiH: {backgroundColor: "rgb(255, 255, 255)"}}} {...addPropertyOverrides({ejpRcwCiH: {"data-framer-name": "White"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 24, intrinsicWidth: 24, pixelHeight: 24, pixelWidth: 24, src: "https://framerusercontent.com/images/NnqsFqKl6wK8k4dA1iMhd1FZpwY.svg"}} className={"framer-1qkg9xl"} layoutDependency={layoutDependency} layoutId={"RWAyZTSh_"}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-SsOwH.framer-13mnv86, .framer-SsOwH .framer-13mnv86 { display: block; }", ".framer-SsOwH.framer-l9fx6z { height: 44px; overflow: hidden; position: relative; width: 44px; will-change: var(--framer-will-change-override, transform); }", ".framer-SsOwH .framer-1qkg9xl { flex: none; height: 24px; left: calc(50.00000000000002% - 24px / 2); position: absolute; top: calc(50.00000000000002% - 24px / 2); width: 24px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 44
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ejpRcwCiH":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerX7sXFezi8: React.ComponentType<Props> = withCSS(Component, css, "framer-SsOwH") as typeof Component;
export default FramerX7sXFezi8;

FramerX7sXFezi8.displayName = "icon-button";

FramerX7sXFezi8.defaultProps = {height: 44, width: 44};

addPropertyControls(FramerX7sXFezi8, {variant: {options: ["nPgUauaQN", "ejpRcwCiH"], optionTitles: ["Yellow", "White"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerX7sXFezi8, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})